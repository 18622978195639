// hooks/useFormValidation.js
import { useState, useCallback } from "react";

const useFormValidation = () => {
  const [formErrors, setFormErrors] = useState({ email: "", password: "" });

  const validateForm = useCallback((password, userData, buttonName) => {
    let errors = { email: "", password: "" };

    if (buttonName === "register" && password.length < 6) {
      errors.password =
        "Your password must contain a minimum of 6 characters. Please choose a longer password";
    } else if (buttonName === "register" && userData) {
      errors.email =
        "User exists with this Email. Please double-check credentials or reset password.";
    } else if (buttonName === "resetPassword" && userData === null) {
      errors.email = "Can't find user with this email. Please register as a new user.";
    } else if (buttonName === "login" && userData === null) {
      errors.email =
        "Email was not found in the database. Please double-check email and try again.";
    } else if (buttonName === "login" && userData && userData.password !== password) {
      errors.password = "Password is incorrect.";
    }

    setFormErrors(errors);
    return errors;
  }, []);

  return { formErrors, validateForm };
};

export default useFormValidation;
