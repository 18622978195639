import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../Utilities/AuthContext";
import "./Navbar.css";
import Modal from "./Modal";
import ContactUsForm from "./ContactUsForm";
import { FaRegCaretSquareDown } from "react-icons/fa";
import { FcHome } from "react-icons/fc";


function Navbar() {
  const location = useLocation();
  const { logout } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const userName = localStorage.getItem("userName");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleSubmissionStatusChange = (status) => {
    setIsSubmitting(status);
  };

  return (
    <div className="navbar">
      <Link to="/home" className={isActive("/home") ? "active" : ""}>
        <FcHome style={{ fontSize: '24px' }}  />
      </Link> 
      <div className="user-dropdown">
        <button className="user-button">
          {userName} <FaRegCaretSquareDown />
        </button>
        <div className="dropdown-content">
          <div tabIndex="0" role="button" className="dropdown-item" onKeyPress={(e) => e.key === "Enter" && openModal()} onClick={openModal}>
            Contact Us
          </div>
          <div onClick={handleLogout} className="dropdown-item logout-link">
            Log Out
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} isSubmitting={isSubmitting} contentType="modal">
        <ContactUsForm closeModal={closeModal} onSubmissionStatusChange={handleSubmissionStatusChange} />
      </Modal>
    </div>
  );
}

export default Navbar;
