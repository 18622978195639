import React, { useState } from "react";
import TeamLineItemsTable from "./TeamLineItemsTable";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { AiOutlineArrowDown } from "react-icons/ai";
import { TbGolfOff } from "react-icons/tb";
import moment from "moment";

function TeamTable({ teams, teamLineItems }) {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (teamId) => {
    setExpandedRows((prev) => ({ ...prev, [teamId]: !prev[teamId] }));
  };

  const canSeeLineItems = (dateString, ownerId) => {
    if (!dateString) {
      return false;
    }

    const startDate = moment(dateString).tz("America/Los_Angeles");
    const currentDate = moment().tz("America/Los_Angeles").startOf("day");
    const loggedInUser = localStorage.getItem("userId");


    const tournamentStarted = startDate.isSameOrBefore(currentDate);
    const isOwnerLoggedInUser = ownerId.toString() === loggedInUser;
    const isSpecialUser = ['4', '83'].includes(loggedInUser);

    return tournamentStarted || isOwnerLoggedInUser || isSpecialUser;
  };

  return (
    <table className="team-table">
      <thead>
        <tr>
          <th className="team-th" style={{ textAlign: "center" }}>
            Rank
          </th>
          <th className="team-th" style={{ textAlign: "center" }}>
            Team
          </th>
          <th className="team-th" style={{ textAlign: "center" }}>
            Rank (Avg)
          </th>
          <th className="team-th" style={{ textAlign: "center" }}>
            Team Total
          </th>
          <th className="team-th" style={{ textAlign: "center" }}>
            +/-
          </th>
          <th className="team-th" style={{ textAlign: "center" }}>
            Show
          </th>
        </tr>
      </thead>
      <tbody>
        {teams.map((team) => (
          <React.Fragment key={team.id}>
            <tr className={expandedRows[team.id] ? "expanded-row" : ""}>
              <td className="team-td">{team.rank}</td>
              <td className="team-td">{team.owner.full_name}</td>
              <td className="team-td">{parseFloat(team.team_world_ranking_average).toFixed(2)}</td>
              <td className="team-td">{team.team_score_total}</td>
              <td className="team-td">{team.team_score_to_par}</td>
              <td className="team-td">
                {canSeeLineItems(team.pool.tournament_fixture?.start_date, team.owner.id) ? (
                  <button onClick={() => toggleRow(team.id)} className="expand-collapse-btn">
                    {expandedRows[team.id] ? <AiOutlineArrowDown /> : <AiOutlineArrowLeft />}
                  </button>
                ) : (
                  <TbGolfOff title="Team players will be viable when tournament begins" />
                )}
              </td>
            </tr>
            {expandedRows[team.id] && (
              <tr>
                <td colSpan="6">
                  <TeamLineItemsTable lineItems={teamLineItems} teamId={team.id} />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}

export default React.memo(TeamTable);
