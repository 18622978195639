import { supabase } from "../ReusableComponents/supabaseClient";
import moment from "moment";
import "moment-timezone";

export async function fetchInviteLinksByUserId(userId) {
  try {
    const { data, error } = await supabase
      .from("InviteLink")
      .select(
        `
        id,
        user,
        created_date,
        modified_date,
        paid,
        person,
        pool:pool(id, tournamentsFixture:tournamentsFixture(name,start_date,end_date))
      `
      )
      .eq("person", userId)
      .order("created_date", { ascending: true }); // Change to ascending order

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return [];
  }
}

export const fetchPoolModalData = async (poolId) => {
  try {
    const { data, error } = await supabase
      .from("pool")
      .select(
        `
        id,
        all_players_must_make_cut,
        num_player_used_for_scoring,
        max_players,
        tie_breaker,
        tournament_fixture(id,name,start_date),
        number_of_teams,
        name,
        owner
      `
      )
      .eq("id", poolId); 


    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching pool details:", error.message);
    return null;
  }
};

export const updatePoolDetails = async (poolData) => {
  const { data: tournament, error: tournamentError } = await supabase.from("tournamentsFixture").select("id, name, start_date").eq("id", poolData.tournament);

  if (tournamentError) {
    console.error("Error fetching tournament:", tournamentError);
    return { error: tournamentError };
  }

  const formattedDate = moment(tournament.start_date).format("MMM Do, YYYY");

  const poolName = tournament[0].name + " " + formattedDate;

  const { data, error } = await supabase
    .from("pool")
    .update({
      name: poolName,
      all_players_must_make_cut: poolData.allMakeCut,
      num_player_used_for_scoring: poolData.numPlayersScored,
      max_players: poolData.teamSize,
      tie_breaker: poolData.tieBreaker,
      tournament_fixture: poolData.tournament,
    })
    .eq("id", poolData.poolId);

  if (error) {
    throw error;
  }

  return data;
};

export async function fetchTournamentOptions() {
  try {
    const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const { data, error } = await supabase
      .from("tournamentsFixture")
      .select("id, name, start_date")
      .gte("start_date", currentDate) // Filter tournaments where start_date is greater than or equal to the current date
      .order("start_date", { ascending: true });
    if (error) {
      throw error;
    }
    return data || [];
  } catch (error) {
    console.error("Error fetching tournament options:", error.message);
    return [];
  }
}

export function validateFormData(formData) {
  const errors = {};

  if (!formData.tournament) {
    errors.tournament = "Tournament is required";
  }

  if (!formData.tieBreaker) {
    errors.tieBreaker = "Tie Breaker is required";
  }

  if (formData.teamSize < 1 || formData.teamSize > 15) {
    errors.teamSize = "Team Size must be between 1 and 15";
  }

  if (formData.numPlayersScored < 1 || formData.numPlayersScored > 15) {
    errors.numPlayersScored = "# of Players must be between 1 and 15";
  }

  if (formData.numPlayersScored > formData.teamSize) {
    errors.numPlayersScored = "Must be less or equal to Max Players";
  }

  return errors;
}

async function createPool(formData, userId, tourneyId, formattedDate, currentDate) {
  try {
    const { data: tournament, error: tournamentError } = await supabase.from("tournamentsFixture").select("id, name, start_date").eq("id", tourneyId);

    if (tournamentError) {
      console.error("Error fetching tournament:", tournamentError);
      return { error: tournamentError };
    }

    const poolName = tournament[0].name + " " + formattedDate;

    const { data: pool, error: poolError } = await supabase
      .from("pool")
      .insert([
        {
          name: poolName,
          all_players_must_make_cut: formData.allMakeCut,
          num_player_used_for_scoring: parseInt(formData.numPlayersScored, 10),
          max_players: parseInt(formData.teamSize, 10),
          tie_breaker: formData.tieBreaker,
          tournament_fixture: tourneyId,
          owner: userId,
          created_date: currentDate,
          modified_date: currentDate,
          tour_fixtures: 10
        },
      ])
      .select();

    if (poolError) {
      console.error("Error creating pool:", poolError);
      return { error: poolError };
    }

    return { pool: pool[0], poolName: poolName };
  } catch (error) {
    console.error("Error creating pool:", error);
    return { error: error.message };
  }
}

async function createInviteLink(poolId, userId, currentDate) {
  try {
    const { data: inviteLink, error: inviteLinkError } = await supabase
      .from("InviteLink")
      .insert([
        {
          pool: poolId,
          person: userId,
          user: true,
          created_date: currentDate,
          modified_date: currentDate,
        },
      ])
      .select();

    if (inviteLinkError) {
      console.error("Error creating invite link:", inviteLinkError);
      return { error: inviteLinkError };
    }

    return { inviteLink: inviteLink };
  } catch (error) {
    console.error("Error creating invite link:", error);
    return { error: error.message };
  }
}

export async function savePoolAndInvite(formData) {
  try {
    const userId = localStorage.getItem("userId");
    const currentDate = moment().tz("America/Chicago").utc().format();
    const tourneyId = parseInt(formData.tournament, 10);
    const formattedDate = moment(formData.start_date).format("MMM Do, YYYY");

    const { pool, poolName, error: poolError } = await createPool(formData, userId, tourneyId, formattedDate, currentDate);

    if (poolError) {
      return { error: poolError };
    }

    console.log("pool", pool.id);

    const { inviteLink, error: inviteLinkError } = await createInviteLink(pool.id, userId, currentDate);

    if (inviteLinkError) {
      return { error: inviteLinkError };
    }

    return { pool: pool, inviteLink: inviteLink, poolName: poolName };
  } catch (error) {
    console.error("Error saving pool and invite:", error);
    return { error: error.message };
  }
}


export async function fetchInviteLinksByPool(poolId) {
  try {
    const { data, error } = await supabase
      .from("InviteLink")
      .select(`
        id,
        user,
        email,
        created_date,
        modified_date,
        paid,
        person:person( id, email, full_name),
        team_created,
        pool:pool(id, tournamentsFixture:tournamentsFixture(name, start_date, end_date))
      `)
      .eq("pool", poolId);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
    return [];
  }
}

export async function updateInviteLinkPaidStatus(linkId, paidStatus) {
  try {
    const { data, error } = await supabase
      .from("InviteLink")
      .update({ paid: paidStatus })
      .match({ id: linkId });

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error updating paid status:", error.message);
    return null;
  }
}


// In a suitable file like api.js or directly in ManagePoolEntries.js if used only there

export const resendInviteEmail = async (email, poolId) => {
  try {
    const response = await fetch('/api/email/invitePlayerEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, poolId, isResend: true }),
    });
    return response; // This will be handled in handleResendEmail
  } catch (error) {
    console.error('Failed to send request:', error);
    throw error; // This will be caught by the catch block in handleResendEmail
  }
};


export const canEditRecord = (dateString) => {
  if (!dateString) {
    return false;
  }

  const startDate = moment(dateString).tz("America/Los_Angeles");
  const currentDate = moment().tz("America/Los_Angeles").startOf("day");

  return startDate.isAfter(currentDate);
};