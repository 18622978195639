import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { supabase } from "../ReusableComponents/supabaseClient";


export function useFetchUser() {
  const [loading, setLoading] = useState(false);

  const fetchUser = async (email) => {
    setLoading(true);
    try {
      let { data, error } = await supabase
        .from("user")
        .select("id, email, password, full_name")
        .eq("email", email.toLowerCase());

      if (error) {
       // toast.error(error.message);
        return null;
      }

      if (data && data.length === 1) {
        return data[0];
      }else{
        return null;
      }

    } catch (error) {
   //   toast.error(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { fetchUser, loading };
}
