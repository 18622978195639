// src/hooks/useForm.js
import { useState, useCallback } from "react";

const useForm = (initialState, initialErrors) => {
  const [formState, setFormState] = useState(initialState);
  const [formErrors, setFormErrors] = useState(initialErrors);

  const handleInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setFormState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setFormState]
  );

  const clearFormErrors = useCallback(() => {
    setFormErrors(initialErrors);
  }, [initialErrors, setFormErrors]);

  const handleTabClick = useCallback(
    (tab) => {
      if (tab === "register") {
        setFormState({ ...initialState, isLogin: false });
      } else {
        setFormState({ ...initialState, isLogin: true });
      }
      clearFormErrors();
    },
    [setFormState, clearFormErrors, initialState]
  );

  const handleForgotPassword = useCallback(() => {
    setFormState((prevState) => ({ ...prevState, forgotPassword: true }));
    clearFormErrors();
  }, [setFormState, clearFormErrors]);

  const resetFormKeepEmail = useCallback(
    (email) => {
      setFormState((prevState) => ({
        ...prevState,
        isLogin: true,
        forgotPassword: false,
        email: email,
        password: "", //determine if you want to bring the password over
        firstName: "",
        lastName: "",
        group: "",
      }));
      clearFormErrors();
    },
    [setFormState, clearFormErrors]
  );

  return {
    formState,
    setFormState,
    formErrors,
    setFormErrors,
    handleInputChange,
    clearFormErrors,
    handleTabClick,
    handleForgotPassword,
    resetFormKeepEmail,
  };
};

export default useForm;
