import React, { useEffect, useState } from "react";
import "./PoolDetailsModal.css"; // Import CSS file for styling
import { fetchTournamentOptions, validateFormData, canEditRecord } from "./PoolModalHelper";
import { GiGolfTee } from "react-icons/gi";
import "./PoolForm.css";

const PoolForm = ({ mode, handleSave, handleClose, poolData, isPoolOwner }) => {
  const [tournamentOptions, setTournamentOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const hasTeams = poolData && poolData.number_of_teams != null && poolData.number_of_teams > 0 && mode !== 'insert';


  const [formData, setFormData] = useState({
    poolId: "",
    tournament: "",
    tournamentName: "", 
    teamSize: "",
    allMakeCut: false,
    numPlayersScored: "",
    tieBreaker: "",
  });

  useEffect(() => {
    let isMounted = true; // Flag to handle asynchronous call
    const fetchOptions = async () => {
      try {
        const options = await fetchTournamentOptions();
        if (isMounted) {
          setTournamentOptions(options);
        }
      } catch (error) {
        console.error("Error fetching tournament options:", error);
        // Optionally update the state to show an error or retry mechanism
      }
    };

    fetchOptions();
    return () => {
      isMounted = false; // Cleanup the effect
    };
  }, []);

  useEffect(() => {
    // Reset form only when the mode changes to insert or when a different pool is edited
    setFormData({
      poolId: mode === "edit" && poolData ? poolData.id : "",
      tournament: mode === "edit" && poolData ? poolData.tournament_fixture.id : "",
      tournamentName: mode === "edit" && poolData ? poolData.tournament_fixture.name : "",
      teamSize: mode === "edit" && poolData ? poolData.max_players : "",
      allMakeCut: mode === "edit" && poolData ? poolData.all_players_must_make_cut : false,
      numPlayersScored: mode === "edit" && poolData ? poolData.num_player_used_for_scoring : "",
      tieBreaker: mode === "edit" && poolData ? poolData.tie_breaker : "",
    });
  }, [mode, poolData]);

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateFormData(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      handleSave(formData);
      handleClose();
    }
  };


  const renderForm = () => (
    <form>
      <div className="pool-form-output">
        <div className="form-row-output-id">
          <label htmlFor="poolId">Pool#:</label>
          <div>{formData.poolId}</div>
        </div>
        <div className="form-row-output">
          <label htmlFor="tournament">Tournament:</label>
          <div>{formData.tournamentName}</div>
        </div>
        <div className="form-row-output">
          <label htmlFor="teamSize">Team Size:</label>
          <div>{formData.teamSize}</div>
        </div>
        <div className="form-row-output">
          <label htmlFor="allMakeCut">All Make Cut:</label>
          <div>{formData.allMakeCut ? "Yes" : "No"}</div>
        </div>
        <div className="form-row-output">
          <label htmlFor="numPlayersScored"># Players Scored:</label>
          <div>{formData.numPlayersScored}</div>
        </div>
        <div className="form-row-output">
          <label htmlFor="tieBreaker">Tie Breaker:</label>
          <div>{formData.tieBreaker}</div>
        </div>
      </div>

      <div className="output-form"></div>
    </form>
  );

  return (
    <div className="pool-form-container">
      {mode === "insert" || (isPoolOwner && mode === "edit" && canEditRecord(poolData.tournament_fixture?.start_date) ) ? (
        <form onSubmit={handleSubmit}>
          <div className="pool-form"> 
          {mode === "edit" && (
            <div className="form-row-id">
              <label htmlFor="poolId" className="poolFormLabel">
                Pool #:
              </label>
              <div>{formData.poolId}</div>
            </div>
          )}

          <div className="form-row">
            <label htmlFor="tournament" className="poolFormLabel">
              Tournament:
            </label>
            <select id="tournament" name="tournament" className="inputTextAreaStylePool" value={formData.tournament} onChange={handleFormChange}>
              <option value="">Select Tournament</option>
              {tournamentOptions.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          {errors.tournament && <div className="error-message-pool-form">{errors.tournament}</div>}

          <div className="form-row">
            <label htmlFor="teamSize" className="poolFormLabel">
              Team Size:
            </label>
            <input type="number" id="teamSize" name="teamSize" min={0} max={15} className="inputTextAreaStylePool" value={formData.teamSize} onChange={handleFormChange} disabled={hasTeams} />
          </div>
          {errors.teamSize && <div className="error-message-pool-form">{errors.teamSize}</div>}

          <div className="form-row inputCheckboxPool">
            <label htmlFor="allMakeCut" className="poolFormLabel">
              All Make Cut?
            </label>
            <input type="checkbox" id="allMakeCut" name="allMakeCut" checked={formData.allMakeCut} onChange={handleFormChange} />
          </div>

          <div className="form-row">
            <label htmlFor="numPlayersScored" className="poolFormLabel">
              # Players scored:
            </label>
            <input type="number" id="numPlayersScored" name="numPlayersScored" min={0} max={15} className="inputTextAreaStylePool" value={formData.numPlayersScored} onChange={handleFormChange} />
          </div>
          {errors.numPlayersScored && <div className="error-message-pool-form">{errors.numPlayersScored}</div>}

          <div className="form-row">
            <label htmlFor="tieBreaker" className="poolFormLabel">
              Tie Breaker:
            </label>
            <select id="tieBreaker" name="tieBreaker" className="inputTextAreaStylePool" value={formData.tieBreaker} onChange={handleFormChange}>
              <option value="" disabled>
                Select Tie Breaker
              </option>
              <option value="Average World Ranking">Average World Ranking</option>
              <option value="Winning Score">Winning Score</option>
            </select>
          </div>
          {errors.tieBreaker && <div className="error-message-pool-form">{errors.tieBreaker}</div>}

          <div className={`form-button-row ${mode === "insert" ? "insert-mode" : ""}`}>
            <button className="button-save" type="submit">
              Save
              <GiGolfTee className="saveIcon" />
            </button>
          </div>
          </div>
          <div className={mode === 'insert' ? 'input-form-insert' : 'input-form-edit'}></div>
        </form>
      ) : (
        renderForm()
      )}
    </div>
  );
};

export default React.memo(PoolForm);
