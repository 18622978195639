import React, { useState } from "react";
import "./HomeBackgroundWrapper.css";
import backgroundImage from "../images/loginBackground.png";
import Modal from "../ReusableComponents/Modal";
import ContactUsForm from "../ReusableComponents/ContactUsForm";

const HomeBackgroundWrapper = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmissionStatusChange = (status) => {
    setIsSubmitting(status);
  };

  return (
    <div className="background-wrapper" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {children}

      <div className="footer">
        <p className="footnote">
          Questions or need help getting started?{" "}
          <button onClick={openModal} className="contact-button">
            Contact Us
          </button>
        </p>
      </div>

      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} isSubmitting={isSubmitting}>
          <ContactUsForm closeModal={closeModal} onSubmissionStatusChange={handleSubmissionStatusChange} />
        </Modal>
      )}
    </div>
  );
};

export default HomeBackgroundWrapper;
