import React, { useState, useCallback, useEffect } from "react";
import { TfiSave } from "react-icons/tfi";
import { 
  useGetPlayerTournamentFixtures, 
  useInsertTeamRecord, 
  useInsertTeamLineItems, 
  setTeam, fetchTeamId, 
  isOptionDisabled, 
  validateSelections, 
  setTeamLineItems
 } from "./TeamHelper";

const PlayerSelectDropdown = React.memo(({ index, playerFixtures, selectedPlayers, onPlayerSelect }) => (
  <div className="form-element">
    <label htmlFor={`player-${index + 1}`}>Player {index + 1}:</label>
    <select id={`player-${index + 1}`} onChange={(e) => onPlayerSelect(index, e)} className="inputTextAreaStyleTeam">
      <option value="" hidden>
        Select a Player
      </option>
      {playerFixtures.map((fixture) => (
        <option
          key={fixture.key}
          value={fixture.key}
          data-player-fixture={fixture.key}
          data-player-id={fixture.playerId}
          data-player-name={fixture.playerName}
          data-world-ranking={fixture.worldRanking}
          disabled={isOptionDisabled(fixture.key, selectedPlayers, index)}
        >
          {fixture.value}
        </option>
      ))}
    </select>
  </div>
));

const TeamSelectForm = ({ poolData, setSpinner, setFormError, setRefetchTrigger }) => {
  const { playerFixtures, error } = useGetPlayerTournamentFixtures(poolData.tournament_fixture.id);
  const [selectedPlayers, setSelectedPlayers] = useState({});
  const { insertTeamRecord } = useInsertTeamRecord();
  const { insertTeamLineItems } = useInsertTeamLineItems();
  const [tieBreakerScore, setTieBreakerScore] = useState("");

  const handleSelectChange = useCallback((playerIndex, event) => {
    const selectedOption = event.target.options[event.target.selectedIndex];
    const playerData = {
      playerFixture: selectedOption.getAttribute("data-player-fixture"),
      playerId: selectedOption.getAttribute("data-player-id"),
      playerName: selectedOption.getAttribute("data-player-name"),
      worldRanking: selectedOption.getAttribute("data-world-ranking"),
    };

    setSelectedPlayers((prevOptions) => ({
      ...prevOptions,
      [playerIndex]: playerData,
    }));
  }, []);

  const handleTieBreakerChange = useCallback((event) => {
    setTieBreakerScore(event.target.value);
  }, []);

  useEffect(() => {
    if (error) {
      setFormError(error);
    }
  }, [error, setFormError]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const validationResult = validateSelections(selectedPlayers, poolData, tieBreakerScore);

      if (validationResult) {
        setFormError(validationResult);
        return;
      }

      try {
        setSpinner(true);
        setFormError(null);
        const teamData = setTeam(poolData, tieBreakerScore);

        if (!teamData || Object.keys(teamData).length === 0) {
          throw new Error("No valid team data provided.");
        }

        await insertTeamRecord(teamData);

        const teamId = await fetchTeamId(poolData.id);

        if (!teamId) {
          throw new Error("Failed to fetch team ID after insertion.");
        }

        const teamLineItems = setTeamLineItems(selectedPlayers, poolData.id, teamId);

        if (!teamLineItems || teamLineItems.length === 0) {
          throw new Error("No valid team line items provided.");
        }

        await insertTeamLineItems(teamLineItems);
        setRefetchTrigger((prev) => prev + 1);

        if (typeof teamId === "number") {
          fetch("/api/email/teamSubmissionEmail", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              teamId: teamId,
            }),
          })
            .then((response) => response.text())
            .then((data) => {
              console.log("Email sent successfully:", data);
            })
            .catch((error) => {
              console.error("Error sending email:", error);
            });
        }
      } catch (error) {
        setFormError(error.message);
      } finally {
        setSpinner(false);
      }
    },
    [poolData, selectedPlayers, tieBreakerScore, setSpinner, setFormError, setRefetchTrigger, insertTeamRecord, insertTeamLineItems]
  );


  return (
    <div>
      {playerFixtures && playerFixtures.length > 0 && (
        <>
          <h2 className="team-pool-name-title">{poolData.name}</h2>
          <div className="pool-data-container">
            <>
              <div className="pool-data-item">
                <span className="pool-data-key">Round:</span>
                <span className="pool-data-value">{poolData.current_round}</span>
              </div>
              <div className="pool-data-item">
                <span className="pool-data-key">Round Status:</span>
                <span className="pool-data-value">{poolData.round_status}</span>
              </div>
              <div className="pool-data-item">
                <span className="pool-data-key">Cut Line:</span>
                <span className="pool-data-value">{poolData.cut_value}</span>
              </div>
              <div className="team-select-form-container">
                <form onSubmit={handleSubmit}>
                  {Array.from({ length: poolData.max_players }, (_, index) => (
                    <PlayerSelectDropdown key={index} index={index} playerFixtures={playerFixtures} selectedPlayers={selectedPlayers} onPlayerSelect={handleSelectChange} />
                  ))}

                  {poolData.tie_breaker === "Winning Score" && (
                    <div className="form-element">
                      <label className="tie-breaker-select-label">Tie Breaker:</label>
                      <input
                        type="number"
                        min={0}
                        title="Tie Breaker: closest to tournament winning score (Total strokes)"
                        className="tieBreakerTextAreaStyleTeam"
                        value={tieBreakerScore}
                        onChange={handleTieBreakerChange}
                        placeholder="Winning Score (Total Strokes)"
                      />
                    </div>
                  )}
                  <div class="team-save-button-container">
                    <button type="submit" className="team-card-button">
                      Submit <TfiSave className="save-team-button-icon" />
                    </button>
                  </div>
                </form>
              </div>
            </>
          </div>
        </>
      )}
    </div>
  );
};

export default TeamSelectForm;
