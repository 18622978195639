import React, { useLayoutEffect, useCallback, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LoginForm from "./LoginForm";
import useForm from "./useForm";
import useWindowSize from "../ReusableComponents/useWindowSize";
import BackgroundWrapper from "./HomeBackgroundWrapper";
import useFormValidation from "./useFormValidation";
import { useFetchUser } from "./useFetchUsers";
import useSupabaseUserAuth from "../Utilities/useSupabaseUserAuth";
import { useAuth } from "../Utilities/AuthContext";
import { debounce } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import PacmanLoader from "react-spinners/PacmanLoader";
import "react-toastify/dist/ReactToastify.css";
import "./Login.css";

function Login() {
  const location = useLocation();

  const navigate = useNavigate();
  const { fetchUser, loading } = useFetchUser();
  const windowSize = useWindowSize();
  const formRef = useRef();
  const [overlaySize, setOverlaySize] = useState({ width: 0, height: 0 });
  const { validateForm } = useFormValidation();
  const { createUser, resetPassword } = useSupabaseUserAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { login } = useAuth();
  const isLoading = loading || isSubmitting;

  const initialFormState = {
    isLogin: true,
    forgotPassword: false,
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    group: "",
  };

  const initialFormErrors = {
    email: "",
    password: "",
  };

  const { formState, formErrors, setFormErrors, handleInputChange, clearFormErrors, handleTabClick, handleForgotPassword, resetFormKeepEmail } = useForm(initialFormState, initialFormErrors);

  useLayoutEffect(() => {
    function updateOverlaySize() {
      if (formRef.current) {
        const rect = formRef.current.getBoundingClientRect();
        setOverlaySize({ width: rect.width, height: rect.height });
      }
    }

    // Initialize MutationObserver
    const observer = new MutationObserver(updateOverlaySize);
    if (formRef.current) {
      observer.observe(formRef.current, { attributes: true, childList: true, subtree: true });
    }

    // Debounced resize listener
    const debouncedUpdateOverlaySize = debounce(updateOverlaySize, 300);
    window.addEventListener("resizeLogin", debouncedUpdateOverlaySize);

    // Initial update
    updateOverlaySize();

    return () => {
      window.removeEventListener("resizeLogin", debouncedUpdateOverlaySize);
      debouncedUpdateOverlaySize.cancel();
      observer.disconnect();
    };
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsSubmitting(true);
      const email = formState.email.trim();
      const password = formState.password.trim();
      const buttonName = event.nativeEvent.submitter.name;
      clearFormErrors();

      const handleRegister = async () => {
        await createUser(formState);
        resetFormKeepEmail(formState.email);
      };

      const handleResetPassword = async (userData, email, password) => {
        await resetPassword(userData, email, password);
        resetFormKeepEmail(email);
      };

      const handleLogin = async (userData) => {
        login(userData.id, userData.full_name, userData.email);

        const from = location.state?.from?.pathname || "/";
        const search = location.state?.from?.search || "";

        if (from !== "/" || search) {
          navigate(`${from}${search}`, { replace: true });
        } else {
          const searchParams = new URLSearchParams(location.search);
          const redirectPath = searchParams.get("redirect") || "/home";
          navigate(`${redirectPath}${location.search}`, { replace: true });
        }
      };

      try {
        let userData = await fetchUser(email);
        const errors = validateForm(password, userData, buttonName);
        setFormErrors(errors);
        console.log("userData", userData);
        if (Object.values(errors).some((error) => error !== "")) {
          setIsSubmitting(false);
          return;
        }

        switch (buttonName) {
          case "register":
            await handleRegister();
            break;
          case "resetPassword":
            await handleResetPassword(userData, email, password);
            break;
          case "login":
            if (userData) await handleLogin(userData);
            break;
          default:
            break;
        }
      } catch (error) {
         toast.error("An unexpected error occurred: " + error.message);
      } finally {
        setIsSubmitting(false);
      }
    },
    [
      formState,
      clearFormErrors,
      setFormErrors,
      validateForm,
      fetchUser,
      createUser,
      login,
      navigate,
      resetFormKeepEmail,
      resetPassword,
      location.search,
      location.state?.from?.pathname,
      location.state?.from?.search,
    ]
  );

  return (
    <div className="main-container">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <BackgroundWrapper windowSize={windowSize}>
        <div className="auth-form-container" ref={formRef}>
          {isLoading && (
            <div
              className="loading-overlay"
              style={{
                width: overlaySize.width,
                height: overlaySize.height,
              }}
            >
              <PacmanLoader className="spinner" color="#d9534f" loading={true} size={25} aria-label="Loading Spinner" />
            </div>
          )}
          <LoginForm
            formState={formState}
            formErrors={formErrors}
            handleInputChange={handleInputChange}
            handleSubmit={handleSubmit}
            handleTabClick={handleTabClick}
            handleForgotPassword={handleForgotPassword}
          ></LoginForm>
        </div>
      </BackgroundWrapper>
    </div>
  );
}

export default Login;
