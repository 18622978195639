import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider, ProtectedRoute } from "./Utilities/AuthContext.js";
import Login from "./LoginComponents/Login";
import BackgroundTheme from "./ReusableComponents/BackgroundTheme";
import Navbar from "./ReusableComponents/Navbar";
import PoolLink from "./PoolLink/PoolLink.js";
import TeamLink from "./TeamLink/Team.js";
import LeaderboardLink from "./LeaderboardLink/Leaderboard.js";


function App() {
  const location = useLocation();
  const showNav = location.pathname !== "/login" && location.pathname !== "/";
 
  return (
    <AuthProvider>
      {showNav && <Navbar />}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/home" element={<ProtectedRoute> <BackgroundTheme><PoolLink /></BackgroundTheme></ProtectedRoute>} />
        <Route path="/team/:poolId" element={<ProtectedRoute> <BackgroundTheme><TeamLink/></BackgroundTheme></ProtectedRoute>}/>
        <Route path="/leaderboard/:poolId" element={<ProtectedRoute> <BackgroundTheme><LeaderboardLink/></BackgroundTheme></ProtectedRoute>}/>
      </Routes>
    </AuthProvider>
  );
}

export default App;
