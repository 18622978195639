import React, { useState } from "react";

function ContactForm({ closeModal, onSubmissionStatusChange  }) {

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isHovered, setIsHovered] = useState(false);
  // const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (isSubmitting) return;
    onSubmissionStatusChange(true);
    try {
      const response = await fetch("/api/email/sendContactUsEmail", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      }
      closeModal();
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      onSubmissionStatusChange(false); // Reset submission state regardless of outcome
    }
  };

  const styles = {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: ".5em"
    },
    form: {
      display: "flex",
      flexDirection: "column",
      gap: ".3rem",
      width: "100%", // Adjust width as necessary, or set a max-width
    },
    field: {
      display: "flex",
      flexDirection: "column",
      width: "100%", // Ensure full width for consistent sizing
    },
    messageField: {
      // Additional styles if needed, but ensure it also has width: '100%'
    },
    submitButton: {
      marginTop: "10px",
      backgroundColor: isHovered ? "#a12f26" : "#c9302c", // Change color based on isHovered
      color: "white",
      border: "none",
      borderRadius: "5px",
      padding: "10px",
      cursor: "pointer",
      fontSize: "16px",
      transition: "background-color 0.3s",
    },
  };

  const inputTextAreaStyle = {
    boxSizing: 'border-box',
    padding: '5px',
    margin: '2px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
    height: 'auto',
    minWidth: '30%',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
  };

  return (
    <div style={styles.formContainer}>
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.field}>
          <input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            style={inputTextAreaStyle}
            onChange={handleChange}
            required
          />
        </div>
        <div style={styles.field}>
          <input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            style={inputTextAreaStyle}
            onChange={handleChange}
            required
          />
        </div>
        <div style={styles.field}>
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            style={inputTextAreaStyle}
            onChange={handleChange}
            required
          />
        </div>
        <div style={{ ...styles.field, ...styles.messageField }}>
          <textarea
            name="message"
            placeholder="Your Message"
            value={formData.message}
            style={inputTextAreaStyle}
            onChange={handleChange}
            required
          />
        </div>
        <button
          type="submit"
          style={styles.submitButton}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          Send
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
