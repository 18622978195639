// LoginForm.js
import React from "react";

import "./Login.css";

function LoginForm({
  formState,
  formErrors,
  handleInputChange,
  handleSubmit,
  handleTabClick,
  handleForgotPassword,
}) {

  const renderFormFields = () => {
    if (formState.forgotPassword) {
      return (
        <>
          <div className="tab-container">
            <div className="tab">
              <div className="tab-item active">Forgot Password</div>
            </div>
          </div>

          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formState.email}
            onChange={handleInputChange}
            className="inputStyle"
            required
          />
          {formErrors.email && (
            <div className="error-message">{formErrors.email}</div>
          )}

          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formState.password}
            onChange={handleInputChange}
            className="inputStyle"
            required
          />
          {formErrors.password && (
            <div className="error-message">{formErrors.password}</div>
          )}
          <button name="resetPassword" type="submit" className="submitButton">
            Reset Password
          </button>

          <button
            type="button"
            className="link-button"
            onClick={() => handleTabClick("login")}
          >
            Return to Login
          </button>
        </>
      );
    } else if (formState.isLogin) {
      return (
        <>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formState.email}
            onChange={handleInputChange}
            className="inputStyle"
            required
          />
          {formErrors.email && (
            <div className="error-message">{formErrors.email}</div>
          )}
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formState.password}
            onChange={handleInputChange}
            className="inputStyle"
            required
          />
          {formErrors.password && (
            <div className="error-message">{formErrors.password}</div>
          )}
          <button name="login" type="submit" className="submitButton">
            Login
          </button>
          <button
            type="button"
            className="link-button"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </button>
        </>
      );
    } else {
      return (
        <>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formState.firstName}
            onChange={handleInputChange}
            className="inputStyle"
            required
          />

          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formState.lastName}
            onChange={handleInputChange}
            className="inputStyle"
            required
          />

          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formState.email}
            onChange={handleInputChange}
            className="inputStyle"
            required
          />
          {formErrors.email && (
            <div className="error-message">{formErrors.email}</div>
          )}
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={formState.password}
            onChange={handleInputChange}
            className="inputStyle"
            required
          />
          {formErrors.password && (
            <div className="error-message">{formErrors.password}</div>
          )}
          <button name="register" type="submit" className="submitButton">
            Register
          </button>
        </>
      );
    }
  };

  return (
    <form className="loginForm" onSubmit={handleSubmit}>
      {!formState.forgotPassword && (
        <div className="tab">
          <div
            className={`tab-item ${formState.isLogin ? "active" : ""}`}
            onClick={() => handleTabClick("login")}
          >
            Login
          </div>
          <div
            className={`tab-item ${!formState.isLogin ? "active" : ""}`}
            onClick={() => handleTabClick("register")}
          >
            Registration
          </div>
        </div>
      )}
      {renderFormFields()}
    </form>
  );
}

export default LoginForm;
