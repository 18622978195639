import React from 'react';
import useWindowSize from "../ReusableComponents/useWindowSize";
import backgroundImage from "../images/pageBackground.png";


const BackgroundTheme = ({ children }) => {
  const windowSize = useWindowSize();

  const backgroundStyle = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundAttachment: 'fixed',
      minHeight: '100vh', 
      width: '100%',
      // minHeight: `${windowSize.height}px`,
      // width: `${windowSize.width}px`,
    };

  return <div style={backgroundStyle}>{children}</div>;
};

export default BackgroundTheme;
