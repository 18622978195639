import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom"; // Import useParams to access URL parameters
import { useFetchPoolAndLineItemData } from "./TeamHelper";
import { ToastContainer } from "react-toastify";
import Spinner from "react-spinners/RingLoader";
import TeamSelectForm from "./TeamSelectForm";
import TeamEditForm from "./TeamEditForm";
import "./Team.css";

const Team = () => {
  const { poolId } = useParams(); // Get poolId from the URL
  const [submittedPoolNumber, setSubmittedPoolNumber] = useState(poolId);
  const [refetchTrigger, setRefetchTrigger] = useState(0);
  const { poolData, teamLineItem, hasLineItems, loading, error } = useFetchPoolAndLineItemData(submittedPoolNumber, refetchTrigger);
  const [spinner, setSpinner] = useState(false);
  const [formError, setFormError] = useState("");
  const showError = error || formError;
  const isLoading = loading || spinner;

  useEffect(() => {
    // Re-fetch data when poolId changes
    setSubmittedPoolNumber(poolId);
  }, [poolId]);

  const renderForm = useCallback(() => {
    if (loading) return null;
    if (hasLineItems && teamLineItem && teamLineItem.length > 0) {
      return <TeamEditForm records={teamLineItem} editCutoffDate={teamLineItem[0].pool.tournament_fixture.start_date} setSpinner={setSpinner} />;
    } else if (poolData) {
      return <TeamSelectForm poolData={poolData} setSpinner={setSpinner} setFormError={setFormError} setRefetchTrigger={setRefetchTrigger} />;
    }
  }, [loading, hasLineItems, teamLineItem, poolData, setSpinner, setFormError, setRefetchTrigger]);

  return (
    <div className="team-page">
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
      <div className="team-card">
        {showError && <p className="message">{error?.message || formError || error}</p>}
          <div className="team-card-body">
            {isLoading ? (
              <div className="loading-team-overlay">
                <Spinner color="#0c9463" loading={true} size={75} aria-label="Loading Spinner" />
              </div>
            ) : (
              renderForm()
            )}
          </div>
      </div>
    </div>
  );
};

export default Team;
