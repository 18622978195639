import React, { useState } from "react";
import { MdDeleteForever } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io";
import Spinner from "react-spinners/RingLoader";
import "./InviteToPool.css";

const EmailInput = ({ email, onChange, onRemove }) => (
  <div className="email-input-row">
    <input type="email" value={email} onChange={onChange} placeholder="Enter participant's email" className="email-input" />
    {onRemove && (
      <button onClick={onRemove} className="email-remove-button">
        <MdDeleteForever />
      </button>
    )}
  </div>
);

const InviteToPool = ({ poolId, userId }) => {
  const [emails, setEmails] = useState([""]);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const isResend = false;

  const handleEmailChange = (index, event) => {
    const newEmails = [...emails];
    newEmails[index] = event.target.value;
    setEmails(newEmails);
  };

  const handleAddEmail = () => setEmails([...emails, ""]);
  const handleRemoveEmail = (index) => setEmails(emails.filter((_, idx) => idx !== index));

  const handleSubmit = async () => {
    setError("");
    setSubmitting(true);
    const validEmails = emails.filter((email) => /\S+@\S+\.\S+/.test(email));
    if (validEmails.length === 0) {
      setError("Please enter valid email addresses.");
      setSubmitting(false);
      return;
    }

    try {
      const results = await Promise.allSettled(
        validEmails.map((email) =>
          fetch("/api/email/invitePlayerEmail", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email, poolId, isResend }),
          })
        )
      );

      const failed = results.filter((result) => result.status === "rejected");
      if (failed.length > 0) {
        setError("Failed to send some invites.");
      } else {
        setEmails([""]);
        setSuccess("All invites sent successfully!");
      }
    } catch (error) {
      setError(`Error sending invites: ${error.message}`);
      setSubmitting(false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="invite-form">
      {submitting && ( <div className="spinner-overlay"> <Spinner color="#0c9463" loading={true} size={150} /> </div>)} 
      {error && <p className="error-message">{error}</p>}
      {success && <p className="success-message">{success}</p>}
      {emails.map((email, index) => (
        <EmailInput key={index} email={email} onChange={(e) => handleEmailChange(index, e)} onRemove={emails.length > 1 ? () => handleRemoveEmail(index) : null} />
      ))}
      <div className="buttons-container">
        <button onClick={handleAddEmail} name="addEmail" className="add-email-button">
          <IoMdPersonAdd />
        </button>
        <button onClick={handleSubmit} disabled={submitting} className="submit-invite-button">
          {submitting ? "Sending..." : "Send Invites"}
        </button>
      </div>
    </div>
  );
};

export default InviteToPool;
