// hooks/supabaseUserAuth.js
import { useCallback } from "react";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "moment-timezone";

const useSupabaseUserAuth = () => {
  const createUser = useCallback(async (formState) => {
    try {
      const createUserResponse = await supabase.from("user").insert([
        {
          first_name: formState.firstName,
          last_name: formState.lastName,
         // group: formState.group,
          email: formState.email.toLowerCase(),
          password: formState.password,
        },
      ]);

      if (createUserResponse.error) {
        toast.error("Error resetting users password: " + JSON.stringify(createUserResponse.error));
      }

      if (!createUserResponse.error) {
        fetch("/api/email/newUserEmail", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: formState.firstName + ' ' + formState.lastName,
            email: formState.email,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Email sent successfully:", data);
          })
          .catch((error) => {
            console.error("Error sending email:", error);
          });
      }
    } catch (error) {
      toast.error("Error creating user: " + JSON.stringify(error.message));
    }
  }, []);

  const resetPassword = useCallback(async (userData, email, password) => {
    try {
      let date = moment().tz("America/Chicago").utc().format();
      const resetPasswordResponse = await supabase
        .from("user")
        .update({ password: password, modified_date: date })
        .match({ email: email.toLowerCase() });

      if (resetPasswordResponse.error) {
        toast.error("Error resetting users password: " + JSON.stringify(resetPasswordResponse.error));
      }
    } catch (error) {
      toast.error("Error resetting users password: " + JSON.stringify(error.message));
    }
  }, []);

  return { createUser, resetPassword };
};

export default useSupabaseUserAuth;
