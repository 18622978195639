import React, { useEffect, useState, useCallback  } from "react";
import Spinner from "react-spinners/RingLoader";
import { fetchInviteLinksByUserId, fetchPoolModalData } from "./PoolModalHelper";
import { supabase } from "../ReusableComponents/supabaseClient";
import NoPoolsImage from "../images/noPoolsToDisplay.png";
import PoolDetailsModal from "../PoolLink/PoolDetailsModal";
import "./PoolLink.css"
import { IoGolfSharp } from "react-icons/io5";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import "moment-timezone";

const NewPoolPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [selectedPool, setSelectedPool] = useState(null);
  const [formMode, setFormMode] = useState("insert");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userId, setUserId] = useState(localStorage.getItem("userId"));

  useEffect(() => {
    const handleStorageChange = () => {
      setUserId(localStorage.getItem("userId"));
    };

    // Add event listener for local storage changes
    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const refreshData = useCallback(async () => {
    if (!userId) return; 
    setLoading(true);
    try {
      const fetchedData = await fetchInviteLinksByUserId(userId);
      setData(fetchedData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
  
    refreshData();

    const inviteLinkSubscription = supabase
      .channel("inviteLinkChanges")
      .on("postgres_changes", { event: "INSERT", schema: "public", table: "InviteLink", filter: `person=eq.${userId}` }, (payload) => {
        refreshData(); // Opt to append to state or refetch as needed
      })
      .on("postgres_changes", { event: "UPDATE", schema: "public", table: "InviteLink", filter: `person=eq.${userId}` }, (payload) => {
        refreshData(); // Opt to update state or refetch as needed
      })
      .subscribe();


    return () => {
      supabase.removeChannel(inviteLinkSubscription);
    };
  }, [userId, refreshData]);


  const openNewPoolModal  = () => {
    setModalIsOpen(true);
    setFormMode("insert");
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const openModalWithData = async (poolId) => {
    setLoading(true);
    try {
      const poolDetails = await fetchPoolModalData(poolId);
      if (poolDetails && poolDetails.length > 0) {
        setSelectedPool(poolDetails[0]); 
        setFormMode("edit"); 
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error("Error fetching pool details: ", error);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="pool-link-table-card">
      {loading && (
        <div className="loading-pool-overlay">
          <Spinner color="#0c9463" loading={true} size={75} aria-label="Loading Spinner" />
        </div>
      )}

      <div className="pool-link-table-container">
        {/* <div className="pool-card-body"> */}
          <div className="new-pool-button-container">
            <button onClick={openNewPoolModal} className="new-pool-link-button">
              New Pool <IoGolfSharp className="new-pool-link-button-icon" />
            </button>
          </div>
          <PoolDetailsModal isOpen={modalIsOpen} onClose={closeModal} poolData={selectedPool} mode={formMode} refreshData={refreshData} loggedInUser={userId} />

          {!loading && data.length === 0 && (
            <div className="no-pool-link-data-container">
              <img src={NoPoolsImage} alt="No data available" />
            </div>
          )}

          {!loading && data.length > 0 && (
            <table className="pool-link-table">
              <thead>
                <tr>
                  <th className="pool-link-th pool-number">Pool #</th>
                  <th className="pool-link-th tournament">Tournament</th>
                  <th className="pool-link-th start-date">Start Date</th>
                  <th className="pool-link-th action">Action</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={item.id} >
                    <td className="pool-link-td">{item.pool?.id}</td>
                    <td className="pool-link-td">{item.pool?.tournamentsFixture.name}</td>
                    <td className="pool-link-td">{moment(item.pool?.tournamentsFixture.start_date).format("MMM Do, YYYY")}</td>
                    <td className="pool-link-td">
                      <button type="button" name="search" className="open-pool-icon-button" aria-label="Edit" onClick={() => openModalWithData(item.pool.id)}>
                        <FaSearch />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    // </div>
  );
};

export default React.memo(NewPoolPage);
