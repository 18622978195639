import React, { useState } from "react";
import Modal from "react-modal";
import { AiOutlineCloseCircle } from "react-icons/ai";
import PoolForm from "./PoolForm";
import { savePoolAndInvite, updatePoolDetails, canEditRecord } from "./PoolModalHelper";
import ModalNavBar from "./ModalNavBar";
import ManagePoolEntries from "./ManagePoolEntries";
import InviteToPool from "./InviteToPool";
import "./PoolDetailsModal.css";

Modal.setAppElement("#root");

const ModalContent = React.memo(function ModalContent({ type, handleSave, handleClose, poolData, mode, loggedInUser, isPoolOwner }) {

  const canEdit = canEditRecord(poolData?.tournament_fixture.start_date);

  switch (type) {
    case "managePoolEntries":
      return <ManagePoolEntries poolId={poolData.id} canEdit={canEdit} />;
    case "invite":
      return <InviteToPool poolId={poolData.id} userId={loggedInUser} />;

    default:
      return <PoolForm handleSave={handleSave} handleClose={handleClose} poolData={poolData} mode={mode} isPoolOwner={isPoolOwner} />;
  }
});

const ContentModal = ({ isOpen, onClose, poolData, mode, refreshData, loggedInUser }) => {
  const isPoolOwner = poolData && poolData.owner != null && poolData.owner === Number(loggedInUser);
  const [activeSection, setActiveSection] = useState("default");

  const handleClose = () => {
    setActiveSection("default");
    onClose();
  };

  const handleSave = async (formData) => {
    try {
      if (mode === "insert") {
        await savePoolAndInvite(formData);
      } else if (mode === "edit") {
        await updatePoolDetails(formData);
        refreshData();
      }
    } catch (error) {
      console.error("Failed to save data:", error);
    }
  };

  const modalContentClass = activeSection === 'managePoolEntries' ? 'modal-content-container-padding' : '';

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Dynamic Content Modal" className="modalContent" overlayClassName="modalOverlay">
      <div className="closeButtonContainer">
        <button type="button" name="close" className="closePoolModalButton" onClick={handleClose}>
          <AiOutlineCloseCircle size="2.5em" />
        </button>
      </div>
      <div className={`modal-content-container ${modalContentClass}`}>
        <div className="modal-content-body">
          <ModalContent
            type={activeSection}
            setContentType={setActiveSection}
            handleSave={handleSave}
            handleClose={handleClose}
            poolData={poolData}
            mode={mode}
            loggedInUser={loggedInUser}
            isPoolOwner={isPoolOwner}
          />
        </div>
      </div>
      <div className="navBarContainer">
        <ModalNavBar activeSection={activeSection} setActiveSection={setActiveSection} isPoolOwner={isPoolOwner} poolRecord={poolData} />
      </div>
    </Modal>
  );
};

export default React.memo(ContentModal);
