import React, { useState, useEffect, useCallback } from "react";
import { FaRegEdit } from "react-icons/fa";
import { FaCheck, FaX } from "react-icons/fa6";
import { useGetPlayerTournamentFixtures, PlayerSelectDisabled, useUpdateTeamLineItem, useUpdateTeamRecord, formatDate, canEditRecord } from "./TeamHelper";

const PlayerSelectDropdown = React.memo(({ playerOptions, selectedPlayerKey, onChange, selectedPlayers, currentDropdownIndex }) => (
  <select value={selectedPlayerKey} onChange={onChange} className="inputTextAreaStyleTeam">
    {playerOptions.map((option) => (
      <option
        key={option.key}
        value={option.key}
        data-player-fixture={option.key}
        data-player-id={option.playerId}
        data-player-name={option.playerName}
        data-world-ranking={option.worldRanking}
        disabled={PlayerSelectDisabled(option.key, selectedPlayers, currentDropdownIndex)}
      >
        {option.value}
      </option>
    ))}
  </select>
));

const TeamEditForm = React.memo(({ records, editCutoffDate }) => {
  const [editingIndex, setEditingIndex] = useState(null);
  const [selections, setSelections] = useState(records);
  const playerOptions = useGetPlayerTournamentFixtures(records[0].pool.tournament_fixture.id);
  const { updateTeamLineItem } = useUpdateTeamLineItem();
  const [tieBreakerValue, setTieBreakerValue] = useState(records[0].team.tie_breaker_score);
  const [isEditingTieBreaker, setIsEditingTieBreaker] = useState(false);
  const canEdit = canEditRecord(editCutoffDate, records[0].pool.owner);
  const { updateTeamRecord } = useUpdateTeamRecord();

  useEffect(() => {
    setSelections(records);
  }, [records]);

  const handleEdit = useCallback((index) => {
    setEditingIndex(index);
  }, []);

  const handleCancel = useCallback(() => {
    setEditingIndex(null);
  }, []);

  const handleSave = useCallback(
    async (index) => {
      setEditingIndex(null);
      const lineItemToUpdate = selections[index];

      if (lineItemToUpdate) {
        await updateTeamLineItem(lineItemToUpdate.id, {
          playerKey: lineItemToUpdate.player,
          worldRanking: lineItemToUpdate.player_world_ranking,
          team: lineItemToUpdate.team,
        });
      }
      setEditingIndex(null);
    },
    [selections, updateTeamLineItem]
  );

  const handleEditTieBreaker = useCallback(() => {
    setIsEditingTieBreaker(true);
  }, []);

  const handleCancelTieBreaker = useCallback(() => {
    setIsEditingTieBreaker(false);
  }, []);

  const handleSaveTieBreaker = useCallback(async () => {
    const teamToUpdate = {
      id: records[0].team.id,
      tie_breaker_score: tieBreakerValue,
    };

    await updateTeamRecord(teamToUpdate);
    setIsEditingTieBreaker(false);
  }, [records, tieBreakerValue, updateTeamRecord]);

  const handleTieBreakerChange = useCallback((e) => {
    setTieBreakerValue(e.target.value);
  }, []);

  const handleChange = useCallback(
    (index, e) => {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const playerKey = e.target.value;
      const worldRanking = selectedOption.dataset.worldRanking;

      const updatedSelections = selections.map((selection, idx) => (idx === index ? { ...selection, player: Number(playerKey), player_world_ranking: Number(worldRanking) } : selection));
      setSelections(updatedSelections);
    },
    [selections]
  );

  const findPlayerOption = useCallback(
    (playerKey) => {
      const playerOption = playerOptions.playerFixtures.find((option) => option.key === playerKey);
      return playerOption ? playerOption.playerName : "Unknown Player";
    },
    [playerOptions]
  );

  const showTieBreakerRow = records[0].pool.tie_breaker === "Winning Score";

  console.log("records " + JSON.stringify(records));

  return (
    <div>
      <h2 className="team-pool-name-title">{records[0].pool.name}</h2>

      <div className="pool-data-container">
        <div className="pool-data-item">
          <span className="pool-data-key">Round:</span>
          <span className="pool-data-value">{records[0].pool.current_round}</span>
        </div>
        <div className="pool-data-item">
          <span className="pool-data-key">Round Status:</span>
          <span className="pool-data-value">{records[0].pool.round_status}</span>
        </div>
        <div className="pool-data-item">
          <span className="pool-data-key">Cut Line:</span>
          <span className="pool-data-value">{records[0].pool.cut_value}</span>
        </div>
        <div className="team-select-form-container">
          <form className="team-edit-form">
            <table className="borderless-table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Player</th>
                  <th scope="col">World Ranking</th>
                  <th scope="col">+/-</th>
                  {canEdit && <th scope="col"></th>}
                </tr>
              </thead>
              <tbody>
                {selections.map((selection, index) => (
                  <>
                    <tr key={index} className="team-edit-item">
                      <td>{index + 1}</td>
                      <td>
                        {editingIndex === index ? (
                          <PlayerSelectDropdown
                            playerOptions={playerOptions.playerFixtures}
                            selectedPlayerKey={selection.player}
                            onChange={(e) => handleChange(index, e)}
                            selectedPlayers={selections}
                            currentDropdownIndex={index}
                          />
                        ) : (
                          findPlayerOption(selection.player)
                        )}
                      </td>

                      <td>{selection.player_world_ranking}</td>
                      <td>{selection.total_score_to_par}</td>
                      {canEdit && editingIndex !== index && (
                        <td>
                          <button type="button" name="edit" className="teamIconButton" aria-label="Edit" onClick={() => handleEdit(index)}>
                            <FaRegEdit />
                          </button>
                        </td>
                      )}

                      {editingIndex === index && (
                        <>
                          <td>
                            <div className="button-container">
                              <button type="button" name="save" className="teamIconButton" aria-label="Save" onClick={() => handleSave(index)}>
                                <FaCheck />
                              </button>
                              <button type="button" name="cancel" className="teamIconButton" aria-label="Cancel" onClick={handleCancel}>
                                <FaX />
                              </button>
                            </div>
                          </td>
                        </>
                      )}
                    </tr>
                    {showTieBreakerRow && index === selections.length - 1 && (
                      <tr className="tie-breaker-row-spacer"></tr> // Customize height as needed
                    )}
                  </>
                ))}

                {showTieBreakerRow && (
                  <tr className="team-edit-item tie-breaker-row">
                    <td>Tie Breaker:</td>
                    <td>Winning Score</td>
                    <td colSpan="1">
                      {isEditingTieBreaker ? (
                        <input type="text" value={tieBreakerValue} onChange={handleTieBreakerChange} className="inputTextAreaStyleTeam" placeholder="Winning score in strokes" />
                      ) : (
                        <span>{tieBreakerValue}</span>
                      )}
                    </td>
                    {!canEdit && <td style={{ display: "none" }}></td>}
                    {canEdit && (
                      <td className="iconTd">
                        {isEditingTieBreaker ? (
                          <>
                            <button type="button" name="save" className="teamIconButton" aria-label="Save" onClick={handleSaveTieBreaker}>
                              <FaCheck />
                            </button>
                            <button type="button" name="cancel" className="teamIconButton" aria-label="Cancel" onClick={handleCancelTieBreaker}>
                              <FaX />
                            </button>
                          </>
                        ) : (
                          <button type="button" name="edit" className="teamIconButton" aria-label="Edit" onClick={handleEditTieBreaker}>
                            <FaRegEdit />
                          </button>
                        )}
                      </td>
                    )}
                    <td></td>
                  </tr>
                )}
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
});

export default TeamEditForm;
