import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TeamTable from "./TeamTable";
import { supabase } from "../ReusableComponents/supabaseClient";
import Spinner from "react-spinners/RingLoader";
import { toast } from "react-toastify";
import {
  useFetchTeamByPool,
  useFetchTeamLineItemsByPool,
  fetchPoolDetailsById,
} from "./LeaderboardHelper";
import "./Leaderboard.css";

function Leaderboards() {
  const { poolId } = useParams();
  const [poolData, setPoolData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { teams, teamLoading } = useFetchTeamByPool(poolId);
  const { teamLineItems, teamLinesLoading } = useFetchTeamLineItemsByPool(poolId);

  useEffect(() => {
    setLoading(true);
    fetchPoolDetailsById(poolId).then(data => {
      setPoolData(data);
      setLoading(false);
    }).catch(err => {
      console.error("Error fetching pool details:", err.message);
      setError('Failed to fetch pool details');
      toast.error('Failed to fetch pool details: ' + err.message);
      setLoading(false);
    });

 
    const poolTableSubscription = supabase
    .channel("poolTableSubscription")
    .on(
      "postgres_changes",
      { event: "UPDATE", schema: "public", table: "pool", filter: `id=eq.${poolId}` },
      (payload) => {
        setPoolData(payload.new);
      }
    )
    .subscribe();

  return () => supabase.removeChannel(poolTableSubscription);


  }, [poolId]);

  if (loading || teamLoading || teamLinesLoading) {
    return <Spinner color="#0c9463" loading={true} size={75} aria-label="Loading Spinner" />;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="leaderboard-page">
      <div className="leaderboard-card">
        {teams.length > 0 ? (
          <div className="leaderboard-card-body">
            {poolData && (
              <>
                <h2 className="pool-name-title">{poolData.name}</h2>
                <div className="leaderboard-data-container">
                  <LeaderboardDataItem label="Tournament Status" value={poolData.tournament_status} />
                  <LeaderboardDataItem label="Current Round" value={poolData.current_round} />
                  <LeaderboardDataItem label="Round Status" value={poolData.round_status} />
                  <LeaderboardDataItem label="Cut Value" value={poolData.cut_value} />
                </div>
              </>
            )}
            <TeamTable teams={teams} teamLineItems={teamLineItems} />
          </div>
        ) : (
          <p className="no-teams-message">Fore! 🏌️ Looks like there are no teams registered for this pool yet ⛳</p>
        )}
      </div>
    </div>
);
}

const LeaderboardDataItem = ({ label, value }) => (
  <div className="leaderboard-data-item">
    <span className="leaderboard-data-key">{label}:</span>
    <span className="leaderboard-data-value">{value}</span>
  </div>
);

export default Leaderboards;
