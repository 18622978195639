import React from "react";
import "./ModalNavBar.css";
import { useNavigate } from "react-router-dom";
import { GiGolfFlag } from "react-icons/gi";
import { TbTournament } from "react-icons/tb";
import { MdManageAccounts } from "react-icons/md";
import { MdLeaderboard } from "react-icons/md";
import { IoMdPersonAdd } from "react-icons/io";
import { canEditRecord } from "./PoolModalHelper";


const ModalNavBar = ({ setActiveSection, activeSection, isPoolOwner, poolRecord }) => {
  const canEdit = canEditRecord(poolRecord?.tournament_fixture.start_date);

  const navigate = useNavigate();

  const navigateToTeam = () => {
    navigate(`/team/${poolRecord.id}`);  // Ensure this matches your route and requirements
  };

  const navigateToLeaderboard = () => {
    navigate(`/leaderboard/${poolRecord.id}`);  // Ensure this matches your route and requirements
  };

  return (
    <div className="modal-nav">
      <button className="modal-nav-button" onClick={navigateToTeam}>
        Team <GiGolfFlag className="golfIcon" />
      </button>
      <button className="modal-nav-button" onClick={navigateToLeaderboard}>
        Leaderboard <MdLeaderboard className="golfIcon" />
      </button>
      {activeSection !== "default" && (
        <button className="modal-nav-button" onClick={() => setActiveSection("default")}>
          Pool <TbTournament className="golfIcon" />
        </button>
      )}
      {activeSection !== "managePoolEntries" && isPoolOwner === true && (
        <button className="modal-nav-button" onClick={() => setActiveSection("managePoolEntries")}>
          Manage <MdManageAccounts className="golfIcon" />
        </button>
      )}

      {activeSection !== "invite" && isPoolOwner === true && canEdit === true && (
        <button className="modal-nav-button" onClick={() => setActiveSection("invite")}>
          Invite <IoMdPersonAdd className="golfIcon" />
        </button>
      )}
      
    </div>
  );
};

export default ModalNavBar;
