import React, { useRef } from "react";
import { IoClose } from "react-icons/io5";
import PacmanLoader from "react-spinners/PacmanLoader";

import "./Modal.css";

function Modal({ isOpen, onClose, children, isSubmitting, contentType }) {
  const modalRef = useRef(null);


  const modalClass = contentType === "video" ? "video-modal" : "modal";

  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className={modalClass} ref={modalRef}>
        {isSubmitting && (
          <div className="loading-overlay">
            <PacmanLoader className="spinner" color="#d9534f" loading={true} size={25} aria-label="Loading Spinner" />
          </div>
        )}
        <button id='closeModal' onClick={onClose} className="closeButton">
          <IoClose />
        </button>
        {children}
      </div>
    </div>
  );
}

export default Modal;
