import { useState, useEffect, useCallback } from "react";
import { supabase } from "../ReusableComponents/supabaseClient";
import { toast } from "react-toastify";
import moment from "moment";
import "moment-timezone";
const isValidPoolNumber = (number) => /^\d+$/.test(number);

export const fetchPoolsForUser = async (userId) => {
  const formattedDate = getTournamentTimeReference();

  try {
    const { data, error } = await supabase
      .from("team")
      .select(
        `
      pool(
        id,
        name,
        tournament_fixture:pool_tournament_fixture_fkey(end_date)
      )
    `
      )
      .eq("owner", userId)
      .order("pool", { ascending: true });

    if (error) {
      console.error("Error fetching pools for user:", error.message);
      throw error;
    }

    const filteredData = data.filter((item) => {
      const poolEndDate = new Date(item.pool.tournament_fixture.end_date);
      return poolEndDate >= new Date(formattedDate);
    });

    const options = filteredData.map((item) => ({
      value: item.pool.id,
      label: `Pool #${item.pool.id}`,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching pools for user:", error.message);
    throw error;
  }
};

export const useFetchPoolAndLineItemData = (poolNumber, refetchTrigger) => {
  const [poolData, setPoolData] = useState(null);
  const [teamLineItem, setTeamLineItem] = useState(null);
  const [hasLineItems, setHasLineItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleError = (err) => {
    setError(err.message);
    setPoolData(null);
    setTeamLineItem(null);
    setHasLineItems(false);
  };

  const convertScore = (score) => {
    if (score === "E") return 0;
    if (score.includes("+")) return parseInt(score.replace("+", ""), 10);
    if (score.includes("-")) return parseInt(score, 10);
    return parseInt(score, 10);
  };

  const sortLineItems = (data) => {
    return data.sort((a, b) => {
      const scoreA = convertScore(a.total_score_to_par);
      const scoreB = convertScore(b.total_score_to_par);
      if (scoreA !== scoreB) return scoreA - scoreB;
      return a.player_world_ranking - b.player_world_ranking;
    });
  };

  const fetchData = useCallback(async () => {
    setError(false);
    setLoading(true);
    setPoolData(null);
    setTeamLineItem(null);
    setHasLineItems(false);

    if (poolNumber && !isValidPoolNumber(poolNumber)) {
      setError("Invalid pool number");
      setLoading(false);
      return;
    }

    const formattedDate = getTournamentTimeReference();

    try {
      const poolQuery = supabase
        .from("pool")
        .select("id, max_players, tie_breaker, name, round_status, current_round, cut_value, tour_fixtures(id, tour_name), tournament_fixture(id, name, start_date), owner(id, full_name, email)")
        .eq("id", poolNumber)
        .gt("tournament_fixture.start_date", formattedDate);

      const lineItemQuery = supabase
        .from("teamLineItem")
        .select(
          "id, player, player_world_ranking, total_score_to_par, team(id, tie_breaker_score), pool (id, name, tie_breaker, cut_value, current_round, round_status, tour_fixtures (id, tour_name), tournament_fixture (id, name, start_date)), owner(id, full_name, email)"
        )
        .eq("pool", poolNumber)
        .eq("owner", Number(localStorage.getItem("userId")));

      const [poolResponse, lineItemResponse] = await Promise.all([poolQuery, lineItemQuery]);

      if (poolResponse.data.length === 0) {
        setError("Pool does not exist");
      } else {
        const firstPoolRecord = poolResponse.data[0];

        if (firstPoolRecord.tournament_fixture === null && lineItemResponse.data.length === 0) {
          setPoolData(null);
          setTeamLineItem(null);
          setError("The entry period has closed for this tournament");
        } else {
          setPoolData(firstPoolRecord);
          const sortedLineItems = sortLineItems(lineItemResponse.data);

          setTeamLineItem(sortedLineItems);
          setHasLineItems(lineItemResponse.data.length > 0);
        }
      }
    } catch (err) {
      handleError(err.message);
    } finally {
      setLoading(false);
    }
  }, [poolNumber]);

  useEffect(() => {
    if (poolNumber) {
      fetchData();
    }
    const lineItemsSubscription = supabase
      .channel("lineItemsSubscription")
      .on("postgres_changes", { event: "UPDATE", schema: "public", table: "teamLineItem", filter: `pool=eq.${poolNumber}` }, (payload) => {
        fetchData();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(lineItemsSubscription);
    };
  }, [fetchData, refetchTrigger, poolNumber]);

  return { poolData, teamLineItem, hasLineItems, loading, error };
};

export const useGetPlayerTournamentFixtures = (tournamentId) => {
  const [playerFixtures, setPlayerFixtures] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data, error } = await supabase.from("playersTournaments").select("id, player(id, player_name, player_Id, world_ranking_position)").eq("tournament", tournamentId).limit(200);

        if (error) throw error;

        if (data.length === 0) {
          setError("Fore! 🏌️ The tournament field has not been set. Please try again on the Monday the week of the tournament ⛳");
        }

        const filteredData = data.filter((item) => item.player && item.player.world_ranking_position && item.player.world_ranking_position !== "");

        // const sortedData = filteredData.sort((a, b) => {
        //   const rankA = Number(a.player.world_ranking_position);
        //   const rankB = Number(b.player.world_ranking_position);
        //   return rankA - rankB;
        // });

        const sortedData = filteredData.sort((a, b) => {
          const nameA = a.player.player_name.toLowerCase();
          const nameB = b.player.player_name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

        const transformedData = transformData(sortedData);

        setPlayerFixtures(transformedData);
        localStorage.setItem("playerFixtures", JSON.stringify(transformedData));
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [tournamentId]);

  return { playerFixtures, loading, error };
};

export const useInsertTeamRecord = () => {
  const [error, setError] = useState(null);

  const insertTeamRecord = useCallback(async (teamData) => {
    try {
      const { error } = await supabase.from("team").insert([teamData]);

      if (error) {
        setError(error.message);
        return;
      }
    } catch (error) {
      setError(error.message);
    }
  }, []);

  return { insertTeamRecord, error };
};

export const useInsertTeamLineItems = () => {
  const [error, setError] = useState(null);

  const [isInserted, setIsInserted] = useState(false);

  const insertTeamLineItems = useCallback(async (teamLineItems) => {
    try {
      const { error } = await supabase.from("teamLineItem").insert(teamLineItems);

      if (error) {
        setError(error.message);
        return;
      }

      setIsInserted(true);
    } catch (error) {
      setError(error.message);
    }
  }, []);

  return { insertTeamLineItems, isInserted, error };
};

export const fetchTeamId = async (poolId) => {
  try {
    const { data, error } = await supabase
      .from("team")
      .select("id")
      .match({ owner: Number(localStorage.getItem("userId")), pool: poolId })
      .order("created_date", { ascending: false })
      .limit(1);

    if (error) {
      toast.error(`Error: ${error.message}`);
      return;
    }

    return data.length > 0 ? data[0].id : null;
  } catch (error) {
    toast.error(`Error: ${error.message}`);
    return null;
  }
};

export const useUpdateTeamLineItem = () => {
  const updateTeamLineItem = async (lineItemId, newPlayerData) => {
    const currentDate = getCurrentCentralTime();

    try {
      const { data, error } = await supabase
        .from("teamLineItem")
        .update({
          player: newPlayerData.playerKey,
          player_world_ranking: newPlayerData.worldRanking,
          modified_date: currentDate,
        })
        .match({ id: lineItemId });

      if (error) {
        toast.error(`Error: ${error}`);
      }

      return data;
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    }
  };

  return { updateTeamLineItem };
};

export const useUpdateTeamRecord = () => {
  const [error, setError] = useState(null);

  const updateTeamRecord = useCallback(async (teamData) => {
    try {
      const { error } = await supabase.from("team").update(teamData).match({ id: teamData.id });

      if (error) {
        setError(error.message);
        throw error;
      }
    } catch (err) {
      setError(err.message);
    }
  }, []);

  return { updateTeamRecord, error };
};

export const setTeam = (poolData, tieBreakerScore) => {
  const currentDate = getCurrentCentralTime();
  const tieBreakerScoreValue = tieBreakerScore && tieBreakerScore.trim() !== "" ? tieBreakerScore : "--";

  return {
    owner: Number(localStorage.getItem("userId")),
    pool: poolData.id,
    created_date: currentDate,
    modified_date: currentDate,
    tie_breaker_score: tieBreakerScoreValue,
  };
};

export const setTeamLineItems = (selectedPlayers, poolId, teamId) => {
  const currentDate = getCurrentCentralTime();

  return Object.values(selectedPlayers).reduce((acc, playerData) => {
    if (!playerData || !playerData.playerId) {
      return acc;
    }

    const teamLineItem = {
      owner: Number(localStorage.getItem("userId")),
      pool: poolId,
      team: teamId,
      player: Number(playerData.playerFixture),
      player_world_ranking: playerData.worldRanking,
      created_date: currentDate,
      modified_date: currentDate,
    };

    acc.push(teamLineItem);
    return acc;
  }, []);
};

export const formatDate = (dateString) => {
  const dateInTargetTimeZone = moment.utc(dateString).tz("America/Los_Angeles", true);
  return dateInTargetTimeZone.format("MMM D, YYYY");
};

const transformData = (data) =>
  data.map((item) => ({
    key: item.player.id,
    value: `${item.player.player_name} (Rank: ${item.player.world_ranking_position})`,
    playerId: item.player.player_Id,
    playerName: item.player.player_name,
    worldRanking: item.player.world_ranking_position,
  }));

function getCurrentCentralTime() {
  return moment().tz("America/Chicago").utc().format();
}

export const isOptionDisabled = (optionPlayerFixture, selectedPlayers, currentDropdownIndex) => {
  return Object.entries(selectedPlayers).some(([index, playerData]) => {
    return index !== currentDropdownIndex.toString() && playerData.playerFixture.toString() === optionPlayerFixture.toString();
  });
};

export const PlayerSelectDisabled = (optionPlayerFixture, selectedPlayers, currentDropdownIndex) => {
  return Object.entries(selectedPlayers).some(([index, playerData]) => {
    return index !== currentDropdownIndex.toString() && playerData.player.toString() === optionPlayerFixture.toString();
  });
};

export const editOptionDisabled = (optionPlayerId, selections, currentDropdownIndex) => {
  return selections.some((selection, index) => {
    // Ensure that we're comparing the same types (e.g., both strings or both numbers)
    return index !== currentDropdownIndex && selection.player.toString() === optionPlayerId.toString();
  });
};

export const validateSelections = (selectedPlayers, poolData, tieBreakerScore) => {
  const playerIds = Object.values(selectedPlayers).map((player) => player.playerId);

  if (playerIds.length !== poolData.max_players || playerIds.some((id) => !id)) {
    return "Please fill all player selections.";
  }

  if (poolData.tie_breaker === "Winning Score" && (tieBreakerScore === undefined || tieBreakerScore <= 0 || tieBreakerScore === null)) {
    return "Please select a tie breaker score";
  }

  const uniquePlayerIds = new Set(playerIds);
  if (uniquePlayerIds.size !== playerIds.length) {
    return "Duplicate player selections are not allowed.";
  }

  return "";
};

function getTournamentTimeReference() {
  const referenceDate = moment().tz("America/New_York").startOf("day");
  const formattedDate = referenceDate.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  return formattedDate;
}

export const canEditRecord = (dateString) => {
  if (!dateString) {
    return false;
  }

  const startDate = moment(dateString).tz("America/Los_Angeles");
  const currentDate = moment().tz("America/Los_Angeles").startOf("day");

  console.log(dateString);

  return startDate.isAfter(currentDate);
};
