import React from "react";
const moment = require("moment-timezone");

function TeamLineItemsTable({ lineItems, teamId }) {
  const filteredLineItems = lineItems.filter((item) => item.team === teamId);
  const italicStyle = { fontStyle: "italic" };
  const strikeThroughStyle = { textDecoration: "line-through" };
  return (
    <table className="team-line-items-table">
      <thead>
        <tr>
          <th className="team-th">Player</th>
          <th className="team-th">Rank</th>
          <th className="team-th">R1</th>
          <th className="team-th">R2</th>
          <th className="team-th">R3</th>
          <th className="team-th">R4</th>
          <th className="team-th">Thru</th>
          <th className="team-th">Total</th>
          <th className="team-th">+/-</th>
        </tr>
      </thead>
      <tbody>
        {filteredLineItems.map((item, index) => {
          const rowStyle = index >= item.pool.num_player_used_for_scoring && item.pool.tournament_status !== "Not Started" ? { ...italicStyle, ...strikeThroughStyle } : null;
          const holesPlayed = ["--", "-"].includes(item.holes_played) && item.tee_time != null ? moment(item.tee_time).format("h:mm ") : item.holes_played;

          return (
            <tr key={item.id} style={rowStyle}>
              <td className="team-td">{item.player.player_name}</td>
              <td className="team-td">{item.player_world_ranking}</td>
              <td className="team-td">{item.round_1_to_par}</td>
              <td className="team-td">{item.round_2_to_par}</td>
              <td className="team-td">{item.round_3_to_par}</td>
              <td className="team-td">{item.round_4_to_par}</td>
              <td className="team-td">{holesPlayed}</td>
              <td className="team-td">{item.total_score}</td>
              <td className="team-td">{item.total_score_to_par}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default React.memo(TeamLineItemsTable);
