import React, { useEffect, useState } from "react";
import "./ManagePoolEntries.css";
import { fetchInviteLinksByPool, updateInviteLinkPaidStatus, resendInviteEmail } from "./PoolModalHelper";
import { MdOutlineMail } from "react-icons/md";
import Spinner from "react-spinners/RingLoader"; // Import Spinner
import moment from "moment";
import "moment-timezone";

const ManagePoolEntries = ({ poolId, canEdit }) => {
  const [inviteLinks, setInviteLinks] = useState([]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadInviteLinks = async () => {
      setIsLoading(true);
      try {
        const data = await fetchInviteLinksByPool(poolId);
        setInviteLinks(data);
      } catch (error) {
        console.error("Failed to fetch invite links:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadInviteLinks();
  }, [poolId]);

  const handlePaidChange = async (id, paid) => {
    await updateInviteLinkPaidStatus(id, paid);
    setInviteLinks(inviteLinks.map((link) => (link.id === id ? { ...link, paid } : link)));
  };

  const handleResendEmail = async (email, poolId) => {
    try {
      const response = await resendInviteEmail(email, poolId);
      if (response.ok) {
        alert("Email resent successfully!");
      } else {
        throw new Error("Failed to resend email");
      }
    } catch (error) {
      console.error(`Error: ${error.message}`);
    }
  };

  return (
    <div className="manage-pool-entries-container" style={{ minHeight: loading ? "auto" : "20vh" }}>
      {loading ? (
        <div className="loading-manage-entries-overlay">
          <Spinner color="#0c9463" size={75} />
        </div>
      ) : (
        <div className="manage-entries-table-container">
          <table className="manage-entries-table">
            <thead className="manage-entries-thead">
              <tr className="manage-entries-tr">
                <th className="manage-entries-th">Person</th>
                <th className="manage-entries-th">Sent</th>
                <th className="manage-entries-th">User</th>
                <th className="manage-entries-th">Team</th>
                <th className="manage-entries-th">Paid</th>
                <th className="manage-entries-th">Resend</th>
              </tr>
            </thead>
            <tbody>
              {inviteLinks.length > 0 ? (
                inviteLinks.map((link) => (
                  <tr key={link.id} className="manage-entries-tr">
                    <td className="manage-entries-td">{link.person?.full_name || link.email}</td>
                    <td className="manage-entries-td">{moment(link.invite_sent).format("MMMM Do")}</td>
                    <td className="manage-entries-td">{link.user ? "Yes" : "No"}</td>
                    <td className="manage-entries-td">{link.team_created ? "Yes" : "No"}</td>
                    <td className="manage-entries-td">
                      <input type="checkbox" className="manage-entries-checkbox" checked={link.paid} onChange={(e) => handlePaidChange(link.id, e.target.checked)} />
                    </td>
                    <td className="manage-entries-td">
                      <div className="resend-button-container">
                        <button className="manage-entries-button" name="resendEmail" onClick={() => handleResendEmail(link.email, link.pool?.id)} disabled={!canEdit}>
                          <MdOutlineMail />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No data available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ManagePoolEntries;
